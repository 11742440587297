<template>
  <div id="app" >
    <div id ='back' :style="{ backgroundImage: 'url(' + src + ')',filter: 'brightness(' + value1 + '%)'}" class="dark" ></div>
    <div class="back">
      <div class="setting"  v-show="settingbutton" @click="setting"><svg t="1609675565846"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2515" width="30" height="30"><path d="M372.134088 1004.846981a509.94041 509.94041 0 0 1-159.298198-76.821527l2.164315-103.514738a93.088793 93.088793 0 0 0-74.238313-93.088794l-101.536602-20.991523A509.428422 509.428422 0 0 1 0.011636 537.983409l82.243949-62.834935a93.088793 93.088793 0 0 0 26.507034-116.104998l-46.89348-92.390627A514.152678 514.152678 0 0 1 172.225904 128.415991l100.419536 25.133974a93.088793 93.088793 0 0 0 107.284834-51.664281l43.007023-94.252403a515.246472 515.246472 0 0 1 88.434354-7.610009c30.137497 0 59.669917 2.606486 88.411081 7.610009l43.007023 94.252403a93.088793 93.088793 0 0 0 107.284834 51.664281l100.396264-25.133974a514.152678 514.152678 0 0 1 110.356765 138.236858l-46.870208 92.390627a93.088793 93.088793 0 0 0 26.483762 116.104998l82.243949 62.834935a509.428422 509.428422 0 0 1-39.213654 172.44699l-101.51333 20.968251a93.088793 93.088793 0 0 0-74.238312 93.088793l2.141042 103.538011a509.94041 509.94041 0 0 1-159.298198 76.798255l-79.684007-66.279221a93.088793 93.088793 0 0 0-119.060567 0l-79.684007 66.302493zM511.348378 721.461421a209.449785 209.449785 0 1 0 0-418.89957 209.449785 209.449785 0 0 0 0 418.89957z" p-id="2516" fill="#ffffff"></path></svg></div>
  <div class="time" v-show="iStime" :style="{ fontSize: timeSize+'px'}" >{{time}}</div>
  <div  v-if="popup" class="popup">
    <div class="settinglist">
      <el-card shadow="hover">
      <div class="search">
        <div class="text">搜索引擎</div>
        <el-select v-model="selectItem" @change="selectFn($event)" placeholder="请选择" >
          <el-option v-for="(item, index) in searchselect" :key="index" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </div>
      </el-card>
      <el-card shadow="hover">
      <div class="block">
        <span class="demonstration">背景图亮度</span>
       <el-slider v-model="value1"></el-slider>
     </div>
      <div class="block">
        <span class="demonstration">时间字体大小</span>
       <el-slider v-model="timeSize"></el-slider>
     </div>
      <div class="block">
        <span class="demonstration">图标圆角大小</span>
       <el-slider v-model="radius" :max ="30"></el-slider>
     </div>
      <div class="block">
        <span class="demonstration">图标透明度</span>
       <el-slider v-model="opacity" :max = '1' :step='0.1'></el-slider>
     </div>
      </el-card>
       <el-card shadow="hover">
         <div class="switch">
      显示每日一句<el-switch v-model="Iscontent" active-color="#13ce66"  inactive-color="#ff4949"></el-switch>
         </div>
          <div class="switch">
      显示搜索框<el-switch v-model="iSsearch" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </div>
          <div class="switch">
      显示时间<el-switch v-model="iStime" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
         </div>
          <div class="switch">
      显示快捷图标<el-switch v-model="iSicon" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
         </div>
      </el-card>
      <el-card shadow="hover">
         <div class="text">重置快捷导航</div>
         <el-row>
         <el-button type="warning" size="small" @click="delicon" round>删除单个</el-button>
         <el-button type="danger" size="small" @click="resticon" round>全部重置</el-button>
         </el-row>
       </el-card>
    </div>
     <div class="savesetting" @click="savesetting">保存设置</div>
     <div class="text">power BY lionjoy</div>
  </div>
    <div id="he-plugin-simple"></div>
      <div class="searchBox" v-show="iSsearch">
       <input v-model="wd" @keyup="keyup($event)" @keydown="keydown($event)" type="text" class="searchInput" />
       <input  v-if="searchbutton" type="button" autofocus="autofocus" @click="rest()" value="清除" class="searchButton">
        <ul class="list-group">
          <li class='list-group-item list-group-item-text'
           v-for="(item,index) in arr" :key="item"
           :class="{'list-group-item-info':index==listIndex}"
            @click="click($event)"> {{item}}</li>
        </ul>
      </div>
      <div class="icons" v-show="iSicon">
      <swiper :options="swiperOption">
         <swiper-slide v-for="(page,index) of pages" :key="index">
          <div class="icon" v-for="(item,index) of page" :key="index">
            <div class="icon-img"  :style="{ borderRadius: radius+'px',opacity:opacity}">
            <img v-show="showicon" :src="item.iconUrl+'/favicon.ico'" @dblclick.stop="openwindow(item.iconUrl)"
                class="icon-img-content"
            ><div v-show="showdel" class="icon-img-content" @click="delitem(item)" >
               <i class="el-icon-delete" ></i>
            </div>
            </div>
            <p class="icon-desc">{{item.desc}}</p>
          </div>
          <div class="icon">
            <div class="icon-img"  @click="dialogFormVisible = true" :style="{ borderRadius: radius+'px',opacity:opacity}">

            <div class="icon-img-content"><i class="el-icon-circle-plus" style="color:#5daf34y;font-size:30px"></i></div>
            </div>
            <p class="icon-desc">新增</p>
          </div>
        </swiper-slide>
         <div class="swiper-pagination"  slot="pagination"> </div>
      </swiper>
      </div>
       <div class="content" v-show="Iscontent">{{ content}}</div>
       <el-dialog title="增加快捷导航" :visible.sync="dialogFormVisible">
  <el-form :model="form">
    <el-form-item label="网址" :label-width="formLabelWidth">
      <el-input v-model="form.iconUrl" autocomplete="off" placeholder="无需输入http://"> <template slot="prepend">Http://</template></el-input>
    </el-form-item>
    <el-form-item label="名称" :label-width="formLabelWidth">
      <el-input v-model="form.desc" autocomplete="off" placeholder="不要太多字，字太多容易显示不全"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="savesite">确 定</el-button>
  </div>
</el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      time: '',
      timer: '',
      src: '',
      iStime: true,
      iSsearch: true,
      Iscontent: true,
      iSicon: true,
      radius: 30,
      timeSize: 80,
      content: '',
      wd: '',
      showicon: true,
      showdel: false,
      arr: [],
      listIndex: -1,
      searchbutton: false,
      popup: false,
      settingbutton: true,
      backnum: 0,
      opacity: 0.8,
      searchselect: [
{value: 'https://www.google.com/search?q=',name: '谷歌'},
        { value: 'https://www.baidu.com/s?ie=UTF-8&wd=', name: '百度' },
        { value: 'https://www.sogou.com/web?query=', name: '搜狗' },
        { value: 'http://so.com/s?q=', name: '360搜索' }
      ],
      selectItem: '',
      value1: 50,
      swiperOption: {
        loop: false,
        autoplay: false,
        pagination: {
          el: '.swiper-pagination'
        }
      },
      iconList: [
        {
          iconUrl:
            'https://www.zhihu.com/',
          desc: '知乎'
        },
        {
          iconUrl:
            'https://v.qq.com',
          desc: '腾讯视频'
        },
        {
          iconUrl:
            'https://weibo.com/',
          desc: '微博'
        },
        {
          iconUrl:
            'https://gitee.com//',
          desc: 'gitee'
        },
        {
          iconUrl:
            'https://www.bilibili.com/',
          desc: 'bilibili'
        },
        {
          iconUrl:
            'https://withpinbox.com/',
          desc: 'pinbox'
        },
        {
          iconUrl:
            'https://www.linerider.com',
          desc: '线条游戏'
        },
        {
          iconUrl:
            'https://www.json.cn',
          desc: 'json格式化'
        }
      ],
      dialogFormVisible: false,
      form: {
        iconUrl: '',
        desc: ''
      },
      formLabelWidth: '120px',
      timeOutEvent: 0
    }
  },
  computed: {
    pages () {
      const pages = []
      this.iconList.forEach((item, index) => {
        const page = Math.floor(index / 6)
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    }
  },
  methods: {
    gettime () {
      this.time = this.$moment().format('HH:mm:ss')
    },
    getimg () {
      this.$axios({
        methods: 'get',
        url: 'http://v3.wufazhuce.com:8000/api/channel/one/0/0'
      }).then(response => {
        this.src = response.data.data.content_list[0].img_url
        this.content += response.data.data.content_list[0].forward
        this.content += '——' + response.data.data.content_list[0].words_info
      })
    },
    openwindow (url) {
      window.open(url)
    },
    delitem (item) {
      var t = this.iconList.indexOf(item)
      console.log(t)
      var len = this.iconList.length
      console.log(len)
      if (len < 2) {
        this.$alert('最少保存一个，否则会有BUG', 'BUG警告', {
          confirmButtonText: '确定'
        })
      }
      this.iconList.splice(t, 1)
    },
    delicon () {
      this.showicon = false
      this.showdel = true
    },
    savesite () {
      this.dialogFormVisible = false
      if (!this.form.iconUrl && !this.form.desc) {
        this.$alert('请输入网址或名称', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      this.form.iconUrl = 'http://' + this.form.iconUrl
      console.log(this.form.iconUrl)
      this.iconList.push(this.form)
      localStorage.setItem('iconlist', JSON.stringify(this.iconList))
      console.log(JSON.parse(localStorage.getItem('iconlist')))
    },
    keyup (event) {
      this.searchbutton = true
      if (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) return
      var url = 'https://www.baidu.com/sugrec?pre=1&p=3&ie=utf-8&json=1&prod=pc&from=pc_web'
      var params = { wd: this.wd }
      this.$jsonp(url, params).then(json => {
        this.arr = json.g.map(x => x.q)
      })
      if (event.keyCode === 27) {
        this.rest()
      }
    },
    click (event) {
      if (event.target.data !== undefined) {
        this.wd = event.target.data
        if (!this.selectItem) {
          window.open('http://so.com/s?q=' + this.wd)
        }
        window.open(this.selectItem + this.wd)
      } else if (event.target.innerText !== undefined) {
        this.wd = event.target.innerText
        if (!this.selectItem) {
          window.open('http://so.com/s?q=' + this.wd)
        }
        window.open(this.selectItem + this.wd)
      }
    },
    rest () {
      this.wd = ''
      this.arr = []
      this.searchbutton = false
    },
    setting () {
      this.popup = true
      this.settingbutton = false
    },
    nextback () {
      if (this.backnum < 6 || this.backnum === 0) {
        this.backnum++
        this.getimg()
        var imgnum = this.backnum
        localStorage.setItem('backnum', imgnum)
      }
    },
    upback () {
      if (this.backnum > 0) {
        this.backnum--
        this.getimg()
        var imgnum = this.backnum
        localStorage.setItem('backnum', imgnum)
      }
    },
    savesetting () {
      this.popup = false
      this.settingbutton = true
      this.showicon = true
      this.showdel = false
      localStorage.setItem('radius', this.radius)
      localStorage.setItem('value1', this.value1)
      localStorage.setItem('iSicon', this.iSicon)
      localStorage.setItem('iStime', this.iStime)
      localStorage.setItem('iScontent', this.Iscontent)
      localStorage.setItem('iSsearch', this.iSsearch)
      localStorage.setItem('timeSize', this.timeSize)
      localStorage.setItem('opacity', this.opacity)
      console.log(localStorage.getItem('radius'))
      localStorage.setItem('iconlist', JSON.stringify(this.iconList))
      console.log(JSON.parse(localStorage.getItem('iconlist')))
    },
    selectFn (e) {
      var searchUrl = this.selectItem
      localStorage.setItem('url', searchUrl)
      var data2 = localStorage.getItem('url')
      console.log(data2)
    },
    resticon () {
      localStorage.removeItem('iconlist')
      window.location.reload()
    },
    keydown (event) {
      if (event.keyCode === 38) {
        this.listIndex--
        if (this.listIndex < 0) {
          this.listIndex = this.arr.length - 1
        }
        this.wd = this.arr[this.listIndex]
      } else if (event.keyCode === 40) {
        this.listIndex++
        if (this.listIndex > this.arr.length - 1) {
          this.listIndex = 0
        }
        this.wd = this.arr[this.listIndex]
      } else if (event.keyCode === 13) {
        if (!this.selectItem) {
          window.open('http://so.com/s?q=' + this.wd)
        }
        window.open(this.selectItem + this.wd)
      }
    }
  },
  watch: {
    wd: function (newval, oldval) {
      if (this.wd === '') {
        this.arr = []
        this.searchbutton = false
        console.log(this.searchbutton)
      }
    }
  },
  created () {
    this.timer = setInterval(this.gettime, 1000)
    this.getimg()
    this.selectItem = localStorage.getItem('url')
    if (parseInt(localStorage.getItem('backnum')) < 6) {
      this.backnum = localStorage.getItem('backnum')
    } else {
      this.backnum = 0
    }
    if (localStorage.getItem('iconlist')) {
      this.iconList = JSON.parse(localStorage.getItem('iconlist'))
    }
    if (localStorage.getItem('iSicon')) {
      this.iSicon = JSON.parse(localStorage.getItem('iSicon'))
    }
    if (localStorage.getItem('iScontent')) {
      this.Iscontent = JSON.parse(localStorage.getItem('iScontent'))
    }
    if (localStorage.getItem('iStime')) {
      this.iStime = JSON.parse(localStorage.getItem('iStime'))
    }
    if (localStorage.getItem('iSsearch')) {
      this.iSsearch = JSON.parse(localStorage.getItem('iSsearch'))
    }
    if (localStorage.getItem('timeSize')) {
      this.timeSize = parseInt(localStorage.getItem('timeSize'))
    }
    if (localStorage.getItem('opacity')) {
      this.opacity = parseFloat(localStorage.getItem('opacity'))
    }
    console.log(localStorage.getItem('iSicon'))
    console.log(localStorage.getItem('backnum'))
    if (localStorage.getItem('value1')) {
      this.value1 = parseInt(localStorage.getItem('value1'))
    }
    if (localStorage.getItem('radius')) {
      this.radius = parseInt(localStorage.getItem('radius'))
    }
  }
}
</script>

<style>
.back{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
  flex-direction:column;
  background-size: cover;
}
.icon{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-img-content{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-img{
  height:50px;
  width: 50px;
  box-shadow:0px 3px 5px 0px #0a0a0a6b;
  background: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-desc{
  color: #fff;
}
.swiper-pagination-bullet-active{
  background: rgba(138, 141, 141, 0.651);
}
.dark{
    filter: brightness(70%);
    position: absolute;
    min-width: 100%;
    min-height: 100vh;
    background-size: cover;
    z-index: -1;
}
.list-group{
    background: #ffffff2e;
    box-shadow: 0px 4px 8px 0px #06060652;
    max-width: 80vw;
    min-width: 30vw;
   list-style: cjk-earthly-branch;
    border-radius: 13px;
}
.swiper-slide{
  display: flex;
}
li {

    padding: 4px 10px;
    font-size: 16px;
    color: #ffffff;
}
.time{
  color: #fff;
  font-size: 45px;
}
.popup{
  min-width: 22vw;
  position: absolute;
  background: #fff;
  height: 100%;
  right: 0px;
  display: flex;
     justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}
.setting{
      width: 100%;
    display: flex;
    align-items: unset;
    justify-content: flex-end;
    margin-right: 20px;
        position: absolute;
    top: 20px;
    right: 2px;
}
.settinglist{
    height: 100%;
    width: 90%;
}
.searchInput{
     border-radius: 20px;
    max-width: 80vw;
    min-width: 30vw;
    height: 30px;
    background: #ffffff73;
    border: none;
    color: #fff;
    text-align: center;
    outline:none;
}
.content{
  color: #fff;
    margin-top: 80px;
    width: 60vw;
    text-align: center;
}
.searchButton{
   background: #ffffff2e;
   padding-left: 10px;
   padding-right: 10px;
   color: #fff;
   border: none;
   border-radius: 10px;
   height: 30px;
   margin-left: 10px;
}
.savesetting {
     background: #de4b4b;
    height: 30px;
    width: 13vw;
    border-radius: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    bottom: 40px;
}
.block{
  width: 80%;
}
.switch{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 10px;
}
.el-card{
  margin: 10px;
}
.el-card__body{
  padding:8px;
}
.text{
  color: #606266;
}
 .swiper-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.recommendPage .swiper-container .swiper-slide{
  width: 100%;
  line-height: 200px;
  background: yellowgreen;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.icons{
  width :47vw;
  height : 130px;
  margin-top : 30px;
}

</style>
