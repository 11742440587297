import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import { VueJsonp } from 'vue-jsonp'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import moment from 'moment'
import { Button, Select, Option, Slider, Card, Input, Dialog, ButtonGroup, Form, FormItem, MessageBox, Switch, Row } from 'element-ui'
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Slider)
Vue.use(ButtonGroup)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Card)
Vue.use(FormItem)
Vue.use(Switch)
Vue.use(Row)
Vue.use(VueAwesomeSwiper)
moment.locale('zh-cn')
Vue.prototype.$moment = moment
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(VueJsonp)

new Vue({
  render: h => h(App)
}).$mount('#app')
